export const WISIFYRECEIVE = "5C44D4C1-9EAD-4A84-A936-30147B46EF9C";
export const WISIFYSEND = "5C44D4D1-9EAD-4A84-A936-30147B46EF9C";
export const WISIFYSERVICE = "5C44D4C0-9EAD-4A84-A936-30147B46EF9C";

export const WISIFYACC_SERVICE = "F20932DE-5A34-44D5-BC23-3AE55D0E9984";
export const WISIFYACC_XYZ = "42058E0E-F904-4B63-B1F5-8AB2C7F7FF69";

export const WISIFYGYRO_SERVICE = "625A1068-3281-45B3-87C8-F66265732DB3";
export const WISIFYGYRO_XYZ = "F3E1E35F-343A-446B-B78A-A28022A4D369";

export const WISIFYBATTSERVICE = "0000180F-0000-1000-8000-00805F9B34FB";
export const WISIFYTBATTRECEIVE = "00002A19-0000-1000-8000-00805F9B34FB";

export const READY_TO_MEASURE = 112;
export const MEASURING = 144;
export const MEASUREMENT_HAS_FINISHED = 160;
export const DOUBLE_TAP = 96;
export const DEFAULT_STATE = 0;

export const MEASURING_TIME_CODE = 65;
export const INACTIVITY_TIME_CODE = 66;
// v1.js wisefybledevicemanager
